@import "./../../styles/se-styles.scss";

.se-data-grid {
  &__toolbar {
    display: flex;
    justify-content: flex-end;
    padding: 10px !important;
  }

  &__export-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "Siemens Sans Roman";
    padding: 0 20px;
    color: $purple-350;

    &:hover {
      color: $purple-500;
    }

    span {
      padding: 0;
    }
  }
}

.admin-data-table {
  padding: 10px 20px 20px;

  p {
    margin-bottom: 20px;
  }
}

.css-qvtrhg-MuiDataGrid-virtualScroller {
  min-height: 100px;
}
