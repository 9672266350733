@import "./../../styles/se-colors.scss";

.current-week-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__week-number {
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 10px;
  }
}
