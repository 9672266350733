@import "../../../../styles/se-colors.scss";

.se-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $neutral-20;
  padding: 0 20px;
  background-color: $neutral-00;
  min-height: 75px;

  &__title {
    display: flex;
    align-items: center;

    h1 {
      padding-left: 20px;
      font-size: 30px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    svg {
      width: 80px;
      height: 28px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__logout {
    margin-left: 20px;
    cursor: pointer;
  }

  &__user-info {
    p {
      margin: 2px;
      font-size: 12px;
      b {
        font-size: 14px;
      }
    }
  }
}
