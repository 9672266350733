@import "./../../styles/se-colors.scss";

.oee-week-picker {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: none;
      color: $purple-350;
      border: 1px solid $purple-350;
      padding: 10px;
      border-radius: 25px;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      margin-left: 10px;
    }
  }
}
