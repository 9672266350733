@import "./../../styles/se-colors.scss";

.resource-selection {
  font-size: 2rem;
  padding: 25px 20px;
  border-radius: 8px;
  border: 1px solid $neutral-40;

  &-week-display{
    display: flex;
    gap: 3rem;
  }
}
