.custom-snackbar {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.custom-alert {
    background-color: #f44336; // Custom background color
    color: white;

    .MuiAlert-icon {
        color: white;
    }

    .MuiAlert-action {
        color: white;
    }
}