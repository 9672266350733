@import "./../../styles/se-colors.scss";

.oee-week-display {
  &__day{
    &-details{
      display: flex;
      padding-bottom: 10px;
    }

    &-interval{
      width: 50px;
      display: flex;
      align-items: center;
      padding-right: 10px;
    }
  }
  &__hour{
    &-details{
      display: flex;
      padding-bottom: 10px
    }

    &-interval{
      width: 60px;
    }
  }

  &__timeline {
    position: relative;
  }

  &__no-events {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__caption {
    display: flex;
    justify-content: flex-end;

    .category {
      display: flex;
      flex-direction: column;
      width: 100px;
      padding-left: 10px;
      &__color {
        height: 20px;
        border-radius: 8px;
      }

      &__label {
        text-align: center;
        padding-top: 10px;
      }
    }
  }
}
