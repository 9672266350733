@import "styles/se-colors.scss";

body {
  margin: 0;
  font-family: "Siemens Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $neutral-10;
}

.layer {
  border-radius: 8px;
  background-color: $neutral-00;
  margin: 0 0 20px;
  padding: 20px;
}

.layout-flex{
  display: flex;
  gap: 3rem;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.layout {
  display: flex;
  height: calc(100% - 116px);

  &__content {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    padding: 20px;
  }
}

.oee-panel {
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__resource-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

p {
  margin: 0;
}


.MuiDataGrid-root .MuiDataGrid-windowContainer {
  overflow: initial;
}

.MuiDataGrid-root .MuiDataGrid-window {
  position: initial;
}

.MuiDataGrid-root .MuiDataGrid-dataContainer {
  height: auto;
  max-height: 50px;  /* You can adjust this to be the max height of your table */
  overflow: auto;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaders {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}


.MuiDataGrid-main {
  height: auto !important;
  max-height: 50vh !important; /* You can adjust this to be the max height of your table */
  overflow: auto !important;
}
