@import "./../../styles/se-colors.scss";

.action-button {
  margin: 10px 0 10px 20px;
  border-radius: 8px;
  background-color: $neutral-40;
  height: 110px;
  width: 110px;
  cursor: pointer;

  p {
    font-size: 30px;
  }

  svg {
    transform: scale(1.8);
    margin-top: 10px;
  }

  &:disabled {
    background-color: $neutral-30;
    color: $white;
    border: none;
    outline: none;
    border: 1px solid $neutral-30;
    cursor: default;
  }

  &> :first-child:not(:only-child) {
    margin-right: 0;
  }
}