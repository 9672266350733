@import "./../../styles/se-colors.scss";

.current-code-display {
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    label {
      font-size: 14px;
      color: $neutral-70;
    }

    svg {
      cursor: pointer;
      padding-left: 10px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__code {
    display: flex;
  }

  &__value {
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    label {
      font-family: "Siemens Sans Bold";
      color: $neutral-60;
      font-size: 16px;
      padding-bottom: 10px;
    }
    span {
      font-family: "Siemens Sans Bold";
      font-size: 18px;
    }
  }
}
