$white: #fff;
$black: #000;

$neutral-00: $white;
$neutral-10: #f2f2f2;
$neutral-20: #eaeaea;
$neutral-30: #dedede;
$neutral-40: #c0c0c0;
$neutral-50: #969696;
$neutral-60: #5f5f5f;
$neutral-70: #393939;
$neutral-80: #1b1b1b;
$neutral-90: #0d0d0d;
$neutral-100: $black;

$neutrals: (
  "neutral-00": $neutral-00,
  "neutral-10": $neutral-10,
  "neutral-20": $neutral-20,
  "neutral-30": $neutral-30,
  "neutral-40": $neutral-40,
  "neutral-50": $neutral-50,
  "neutral-60": $neutral-60,
  "neutral-70": $neutral-70,
  "neutral-80": $neutral-80,
  "neutral-90": $neutral-90,
  "neutral-100": $neutral-100,
);

$purple-200: #cf9bff;
$purple-300: #b96cff;
$purple-350: #8a00e5;
$purple-500: #641e8c;
$purple-600: #1b1534;
$purple-700: #5d596e;
$purple-800: #ada9c2;

$interaction-300: #8a00e5;
$interaction-600: #641e8c;
$interaction-200: #ada9c2;

$danger-300: #da1e28;
$danger-600: #a7070f;
$danger-200: #ada9c2;

$success-300: #007942;
$success-600: #006436;
$success-200: #ada9c2;

$warning: #e2d000;
$success: $success-300;
$info: #0057c6;

$blue-00: #001e52;
$blue-10: #00328c;
$blue-20: #0057c6;
$blue-30: #0084e1;
$blue-40: #1cacfe;
$blue-50: #6addff;

$gray-00: #262c30;
$gray-10: #54646e;
$gray-20: #7b919d;
$gray-30: #96b0c0;
$gray-40: #b8cedb;
$gray-50: #d7e4ee;

$yellow-00: #786400;
$yellow-10: #8c7500;
$yellow-20: #c6ae00;
$yellow-30: #e2d000;
$yellow-40: #f6e600;
$yellow-50: #fff91f;

$brown-00: #3d3535;
$brown-10: #564646;
$brown-20: #7d6766;
$brown-30: #8f7c7a;
$brown-40: #af9e9c;
$brown-50: #cfc0be;

$green-00: #05371e;
$green-10: #006c3b;
$green-20: #099053;
$green-30: #27b66d;
$green-40: #14da79;
$green-50: #00fd79;
