@import "../../../../styles/se-colors.scss";

.side-navbar {
  width: 20%;
  background-color: $white;

  nav {
    display: flex;
    flex-direction: column;
  }

  &__header {
    &__children {
    }
  }
}

.nav-item-header {
  &__item {
    text-decoration: none;
    cursor: pointer;
    padding: 10px 20px;

    text-align: left;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    span {
      font-size: 18px;
    }

    &--children {
      padding: 8px 25px;

      span {
        font-size: 14px;
      }
    }

    &__active {
      background-color: red;
    }
  }

  &__children {
    display: flex;
    flex-direction: column;
  }
}

.active-nav-item {
  color: red;
  font-weight: bold;
}
