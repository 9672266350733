@import "./../../styles/se-colors.scss";

.code-dialog {
  &__selection {
    display: flex;
  }

  &__field {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    label {
      color: #5f5f5f;
      margin-right: 10px;
    }

    &--text {
      padding: 0.4rem 0.5rem;
      width: auto;
      appearance: none;
      background-clip: padding-box;
      background-color: transparent;
      border: 1px solid #dee2e6;
      border-radius: 0.375rem;
      color: #0d0d0d;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}