@font-face {
  font-family: "Siemens Sans Roman";
  src: url("./../assets/fonts/SiemensSans_Prof_Roman.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans Bold";
  src: url("./../assets/fonts/SiemensSans_Prof_Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans Italic";
  src: url("./../assets/fonts/SiemensSans_Prof_Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Sans Bold Italic";
  src: url("./../assets/fonts/SiemensSans_Prof_BoldItalic.woff2")
    format("woff2");
  font-weight: bold;
  font-style: italic;
}
