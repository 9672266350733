@import "./../../styles/se-colors.scss";

.oee-value-display {
  background-color: $green-30;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  color: $white;

  &.red-bg {
    background-color: $danger-300;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
  }

  &__value {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__label {
    font-size: 0.8rem;
  }
}
